<template>
  <div class="createtable">
		<div v-if="topButtonVisible" class="tableUpBtnBox">
			<slot name="topButton"></slot>
		</div>
		<el-table @row-contextmenu="rowContextmenu" ref="table" style="width: 100%" :data="tableData" @selection-change="handleSelectionChange" @sort-change="handdleSort" v-loading="loading" :max-height="800">
			<el-table-column v-if="checkBoxVisible" type="selection" width="55">
			</el-table-column>
			<template v-if="!autoWidth">
				<el-table-column v-for="item in tableDataItem" :sortable="item.sortable ? 'custom' : false" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width" :formatter="formatter">
					<template slot-scope="scope">
						<img class="tableImg" :style="{ height: item.height}" v-if="item.img" :src="scope.row[item.prop].value" alt="">
						<div v-else-if="item.array">
							<ul class="tableUl">
								<li v-for="(itemson, indexson) in scope.row[item.prop]" :key="indexson">
									{{ item.object ? itemson[item.object] : itemson }}
								</li>
							</ul>
						</div>
						<div v-else-if="item.arrayValue">
							<ul class="tableUl" v-if="scope.row[item.prop] && scope.row[item.prop][item.Object]">
								<li v-for="(itemson, indexson) in scope.row[item.prop][item.Object]" :key="indexson">
									<span :class="{'text-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'label', 'true-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'boolean_label' && scope.row[item.prop].value === true,  'false-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'boolean_label' && scope.row[item.prop].value === false }" :style="{color: scope.row[item.prop].color ? scope.row[item.prop].color : ''}">{{itemson}}</span>
								</li>
							</ul>
						</div>
						<div v-else-if="item.Object" class="createtable__text">
							<el-switch :disabled="scope.row[item.prop].disabled" v-if="scope.row[item.prop].type && scope.row[item.prop].type === 'switch'" v-model="scope.row[item.prop].value" @change="() => handleTableSwitchChange(scope.row, item)" />
							<div v-else>
								<span v-if="!Utils.isNull(scope.row[item.prop][item.Object]) && !scope.row[item.prop][item.Object].clickables" :class="{'text-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'label', 'true-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'boolean_label' && scope.row[item.prop].value === true,  'false-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'boolean_label' && scope.row[item.prop].value === false }" :style="{'background-color': scope.row[item.prop].color ? scope.row[item.prop].color : ''}" v-html="getFormateData(scope.row[item.prop][item.Object])"></span>
							</div>
							<div v-if="scope.row[item.prop][item.Object] && scope.row[item.prop][item.Object].clickables">
								<a v-for="(itemhref, indexhref) in scope.row[item.prop][item.Object].clickables" :key="indexhref" :style="{ 'background-color': itemhref.background_color ? itemhref.background_color : 'unset', color: itemhref.textcolor ? itemhref.textcolor : '#409eff', padding: '0 5px', width: 'fit-content'}" :class="{ hrefBlock: true }">
									<span @click="handleForward(itemhref, item.prop)" v-html="itemhref.name"></span>
								</a>
							</div>
						</div>
						<span v-else-if="item.date">{{ Utils.dateFormat(item.date, item.name ? scope.row[item.prop][item.name] : scope.row[item.prop]) }}</span>
						<span class="showForImg" v-else-if="item.span" v-html="scope.row[item.prop]"></span>
					</template>
				</el-table-column>
			</template>
			<template v-else>
				<el-table-column v-for="item in tableDataItem" :sortable="item.sortable ? 'custom' : false" :key="item.prop" :prop="item.prop" :label="item.label" :min-width="item.width" :formatter="formatter">
					<template slot-scope="scope">
						<img class="tableImg" :style="{ height: item.height}" v-if="item.img && scope.row && scope.row[item.prop] && scope.row[item.prop].value" :src="scope.row[item.prop].value" alt="">
						<div v-else-if="item.array">
							<ul class="tableUl">
								<li v-for="(itemson, indexson) in scope.row[item.prop]" :key="indexson">
									{{ item.object ? itemson[item.object] : itemson }}
								</li>
							</ul>
						</div>
						<div v-if="item.Object" class="createtable__text">
							<el-switch :disabled="item.disabled" v-if="scope.row && scope.row[item.prop] && scope.row[item.prop].type && scope.row[item.prop].type === 'switch'" v-model="scope.row[item.prop].value" @change="() => handleTableSwitchChange(scope.row, item)" />
							<div v-else>
								<span v-if="scope.row && scope.row[item.prop] && !Utils.isNull(scope.row[item.prop]) && !Utils.isNull(scope.row[item.prop][item.Object]) && !scope.row[item.prop][item.Object].clickables" :class="{'text-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'label', 'true-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'boolean_label' && scope.row[item.prop].value === true,  'false-bgcolor' : scope.row[item.prop].type && scope.row[item.prop].type === 'boolean_label' && scope.row[item.prop].value === false }" :style="{color: scope.row[item.prop].color ? scope.row[item.prop].color : ''}" v-html="getFormateData(scope.row[item.prop][item.Object])"></span>
							</div>
							<div v-if="scope.row && !Utils.isNull(scope.row[item.prop]) && !Utils.isNull(scope.row[item.prop][item.Object]) && scope.row[item.prop][item.Object].clickables">
								<div v-for="(itemhref, indexhref) in scope.row[item.prop][item.Object].clickables" :key="indexhref" :style="{background : itemhref.color ? itemhref.color: '',padding: itemhref.color ? '0 10px' : ''}">
									<a :style="{ 'background-color': itemhref.background_color ? itemhref.background_color : 'unset', color: itemhref.textcolor ? itemhref.textcolor : '#409eff', padding: '0 5px', width: 'fit-content'}" :class="{ hrefBlock: true }"><span @click="handleForward(itemhref, item.prop)" v-html="itemhref.name"></span></a>
								</div>
							</div>
						</div>
						<span v-else-if="item.date">{{ Utils.dateFormat(item.date, item.name ? scope.row[item.prop][item.name] : scope.row[item.prop]) }}</span>
						<span class="showForImg" v-else-if="item.span && scope.row" v-html="scope.row[item.prop]"></span>
					</template>
				</el-table-column>
			</template>
			<el-table-column v-if="operateVisible" align="left" fixed="right" :label="$t('table.common.operate')" :min-width="operationColWidth">
				<template slot-scope="scope">
					<slot name="rightBtn" :row="scope.row"></slot>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="pageVisible" class="tableUpPage" @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[50, 100, 150, 200]" :page-size="papeSize" layout="total, sizes, prev, pager, next, jumper" :total="pageData.total_count" :current-page="current_page">
		</el-pagination>
		<div ref="contextMenu" v-if="menuPopup" class="contextmenu">
			<div class="contextmenu__item" @click="handleNewTab">{{$t('message.newTab')}}</div>
		</div>
	</div>
</template>

<script>
import Sortable from 'sortablejs'
import Utils from '@/utils/Utils'
export default {
	name: 'createTable',
	props: {
		// 是否显示checkbox
		checkBoxVisible: {
			type: Boolean,
			default() {
				return false
			},
		},
		// 是否展示操作栏
		operateVisible: {
			type: Boolean,
			default() {
				return true
			},
		},
		// 是否展示顶部按钮
		topButtonVisible: {
			type: Boolean,
			default() {
				return false
			},
		},
		// 表格数据
		tableData: {
			type: Array,
			default() {
				return []
			},
		},
		// 表格配置项
		tableDataItem: {
			type: Array,
			default() {
				return []
			},
		},
		pageVisible: {
			type: Boolean,
			default() {
				return false
			},
		},
		pageData: {
			type: Object,
			default() {
				return {}
			},
		},
		papeSize: {
			type: Number,
			default() {
				return 50
			},
		},
		current_page: {
			type: Number,
			default() {
				return 1
			},
		},
		total_count: {
			type: Number,
			default() {
				return 0
			},
		},
		tableHeight: {
			type: Number,
			default() {
				return 300
			},
		},
		btnType: {
			type: Array,
			default() {
				return ['V', 'E', 'D']
			},
		},
		loading: {
			type: Boolean,
			default() {
				return false
			},
		},
		autoWidth: {
			type: Boolean,
			default() {
				return false
			},
		},
		operationColWidth: {
			type: Number,
			default() {
				return 120
			},
		},
	},
	data() {
		return {
			Utils: Utils,
			menuPopup: false,
			viewData: null,
		}
	},
	watch: {
		tableData() {
			this.$refs.table.$refs.bodyWrapper.scrollTop = 0
		},
	},
	mounted() {
		// console.log(this)
		// this.rowDrop()
	},
	destroyed() {
		document.removeEventListener('click', this.viewContentMenu)
	},
	methods: {
		rowContextmenu(row, col, event) {
			if (col.label === this.$t('table.common.operate')) {
				event.preventDefault()
			}
			console.log(event.path[0].innerText)
			if (
				event.path[0].classList.contains('operate_view') ||
				((event.path[0].innerText === this.$t('button.view') ||
					event.path[0].innerText === 'View') &&
					event.path[1].classList.contains('operate_view'))
			) {
				this.menuPopup = true
				this.$nextTick(() => {
					this.viewData = row
					const menu = this.$refs.contextMenu
					const cha = document.body.clientWidth - event.pageX
					// 防止菜单太靠底，根据可视高度调整菜单出现位置
					if (cha < 135) {
						menu.style.left = event.pageX - 135 + 'px'
					} else {
						menu.style.left = event.pageX + 'px'
					}
					menu.style.top = event.pageY + 'px'
				})
				document.addEventListener('click', this.viewContentMenu)
			}
		},
		handleNewTab() {
			this.$emit('handleNewTab', this.viewData)
		},
		viewContentMenu() {
			this.menuPopup = false
		},
		// 行拖拽
		rowDrop() {
			Sortable.create(
				this.$refs.table.$el.children[2].children[0].children[1],
				{
					animation: 150,
					onEnd: (ev) => {
						this.$emit('onRowDropEnd', ev)
					},
				}
			)
		},
		// 自定义排序
		handdleSort(val) {
			let value = this.tableDataItem.filter((item) => item.prop === val.prop)
			value = value.length > 0 ? value[0].values : ''
			this.$emit('handleSort', {
				sort_by:
					val.order === null ? val.order : `${value} ${val.order.slice(0, -6)}`,
			})
		},
		// 表格点击跳转
		handleForward(data, name) {
			if (!data.model_name) return
			this.$emit('forwardToPage', data, name)
		},
		// 操作
		handleClick(type, data) {
			this.$emit('operate', type, data)
		},
		// 删除操作
		// deleteClick (data) {
		//   this.$emit('deleteData', data)
		// },
		getFormateData(data) {
			if (data === true) return this.$i18n.locale === 'zh' ? '是' : 'Yes'
			else if (data === false) return this.$i18n.locale === 'zh' ? '否' : 'No'
			else return data
		},
		// 分页操作
		handleSizeChange(val) {
			// console.log('1', val)
			this.$emit('update:papeSize', val)
		},
		// 分页跳转
		handleCurrentChange(val) {
			// console.log('2', val)
			document.documentElement.scrollTop = 0
			this.$emit('update:current_page', val)
		},
		// 数据选择
		handleSelectionChange(val) {
			// console.log(val)
			this.$emit('selectionChange', val)
		},
		formatter(row, column) {
			// console.log(row, column)
		},
		handleTableSwitchChange(rowData, jsonItemData) {
			this.$emit('onSwitchChange', {
				rowData,
				jsonItemData,
			})
		},
	},
}
</script>

<style lang="scss" scope>
.createtable {
	a {
		cursor: pointer;
	}
	// overflow: auto;
	&__text {
		word-break: break-word;
	}
	.el-table__header-wrapper {
		.cell {
			color: #303133;
		}
	}
	.contextmenu__item {
		display: block;
		line-height: 34px;
		text-align: center;
	}
	.contextmenu__item:not(:last-child) {
		border-bottom: 1px solid rgba(64, 158, 255, 0.2);
	}
	.contextmenu {
		position: absolute;
		background-color: #fff;
		font-size: 12px;
		padding: 2px 8px;
		// color: #409EFF;
		color: #40586f;
		border-radius: 4px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border: 1px solid rgba(64, 158, 255, 0.2);
		white-space: nowrap;
		z-index: 1000;
	}
	.contextmenu__item:hover {
		cursor: pointer;
		// background: #66b1ff;
		border-color: #66b1ff;
		color: #66b1ff;
	}
}
.showForImg {
	img {
		height: 100px;
	}
}
.tableUpBtnBox {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.tableUpPage {
	margin-top: 8px;
}
.tableImg {
	width: 100px;
	// height: 100px;
	object-fit: contain;
}
.tableUl {
	list-style-type: none;
	margin: 0;
	padding: 0;
	> li {
		margin-top: 4px;
		&:first-child {
			margin-top: 0;
		}
	}
}
</style>
