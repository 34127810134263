<template>
  <div class="customeTable">
    <div v-if="tableType === 'object'">
      <el-col :span="item.span ? item.span : custSpan" v-for="item in dataItem" :key="item.prop" v-show="item.show ? item.show : true">
        <div class="customeTable__wrapper customeTable__column">
          <span class="customeTable__wrapper__title">{{data[item.prop] && data[item.prop].name ? data[item.prop].name : ''}}</span>
          <div v-if="item.image && data[item.prop] && data[item.prop].value && (data[item.prop].value instanceof Array)">
            <el-image
              v-for="(imgItem, imgIndex) in data[item.prop].value" :key="'img' + imgIndex"
              style="width: 100px; height: 100px;margin-right: 5px"
              :src="imgItem.value"
              :preview-src-list="[imgItem.value]">
            </el-image>
          </div>
          <div v-else-if="item.image && data[item.prop] && data[item.prop].value && !(data[item.prop].value instanceof Array)">
            <el-image
              style="width: 100px; height: 100px;margin-right: 5px"
              :src="data[item.prop].value">
            </el-image>
          </div>
          <createTable v-else-if="item.table" :autoWidth="true" :tableDataItem="item.tableItem" :operateVisible="false" :tableData="getTableData(data[item.prop])" @forwardToPage="handleCreateTableForwardToPage"></createTable>
          <span class="customeTable__wrapper__data" :class="{'text-bgcolor' : item.back  }" v-else-if="data[item.prop] && ( typeof data[item.prop].value == 'boolean' || data[item.prop].value == 0 || data[item.prop].value) && !data[item.prop].value.clickables" v-html="item.date ? getFormateData(item.format, data[item.prop].value) : ((item.unit || '') + '' +data[item.prop].value)"></span>
          <!-- <span class="customeTable__wrapper__data" :class="{'text-bgcolor' : item.back  }" v-else-if="!data[item.prop].value.clickables" v-html="item.date ? getFormateData(item.format,data[item.prop].value) : data[item.prop].value"></span>-->
          <div v-else-if="data[item.prop] && data[item.prop].value && data[item.prop].value instanceof Object && data[item.prop].value.clickables">
            <a v-for="(itemhref, indexhref) in data[item.prop].value.clickables" :key="indexhref" class="customeTable__wrapper__data"><span @click="handleForward(itemhref, item.prop)" v-html="itemhref.name"></span></a>
          </div>
          <div v-else class="defalutHeight"></div>
        </div>
      </el-col>
    </div>
    <div v-if="tableType === 'array'">
      <el-col :span="custSpan" v-for="item in dataItem" :key="item.name">
        <div class="customeTable__wrapper customeTable__row">
          <el-col :span="12">
            <span class="customeTable__wrapper__title">{{item.name}}</span>
          </el-col>
          <el-col :span="12">
            <span class="customeTable__wrapper__data">{{item.value}}</span>
          </el-col>
        </div>
      </el-col>
    </div>
  </div>
</template>

<script>
import createTable from '@/components/createTable'
import Utils from '@/utils/Utils'
export default {
  components: { createTable },
  props: {
    custSpan: {
      type: Number,
      defalut () {
        return 2
      }
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    dataItem: {
      type: Array,
      defalut () {
        return []
      }
    },
    tableType: {
      type: String,
      defalut () {
        return 'object'
      }
    }
  },
  methods: {
    // init () {
    //   if (this.data.length > 0) {
    //     this.searchFormData.forEach(res => {
    //       this.$set(this.createData, res.query_name, '')
    //     })
    //   }
    // }
    // 表格点击跳转
    handleForward (data, name) {
      if (!data.model_name) return
      this.$emit('forwardToPage', data, name)
    },
    getFormateData (format, data) {
      if (this.$i18n.locale === 'zh') {
        return Utils.dateFormat(format[0], data)
      } else if (this.$i18n.locale === 'en') {
        return Utils.dateFormat(format[1], data)
      }
    },
    getName (data) {
      if (!data) return ''
      const name = []
      data.split('_').forEach(item => {
        name.push(item.slice(0, 1).toUpperCase() + item.slice(1))
      })
      return name.join(' ')
    },
    getTableData (data) {
      // console.log(data)
      if (data instanceof Array) return data
      else return [data]
    },
    handleCreateTableForwardToPage (data, name) {
      this.$emit('createTableForwardToPage', data, name)
    }
  }
}
</script>

<style lang="scss">
.customeTable {
  width: 100%;
  height: 100%;
  > div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    margin-bottom: 10px;
    a {
      color: #409eff;
      cursor: pointer;
    }
    &__title {
      font-size: 12px;
      margin-bottom: 4px;
      font-weight: 700;
      color: #303133;
    }
    &__data {
      font-size: 14px;
      color: #40586F;
    }
    .defalutHeight{
      min-height: 20px;
    }
  }
  &__column {
    flex-direction: column;
    overflow: hidden;
    word-wrap: break-word;
  }
}
</style>
