<template>
  <div class="view-frame">
    <header class="view-frame__header">
      <div class="view-frame__header__left">
        <span v-if="titleObject.show">{{titleObject.title + titleObject.data || ''}}</span>
      </div>
      <div class="view-frame__header__right">
        <slot name="rightBtn"></slot>
        <!-- <el-button></el-button> -->
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    titleObject: {
      type: Object,
      defalut () {
        return {}
      }
    },
    buttonObj: {
      type: Array,
      defalut () {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
.view-frame {
  width: 100%;
  height: 100%;
  &__header {
    width: 100%;
    padding: 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
      > span {
        font-size: 20px;
        color:#606266;
        font-weight: 500;
      }
    }
    &__right {
      width: auto;
    }
  }
}
</style>
