import i18n from '@/i18n/index'

export default {
  historyTableItem: [{
    prop: 'id',
    label: 'ID',
    span: true,
    width: '80'
  }, {
    prop: 'changeset',
    span: true,
    label: i18n.t('form.common.changeHistory'),
    width: '400'
  }, {
    prop: 'created_at',
    label: i18n.t('form.common.creationTime'),
    date: 'YYYY-mm-dd HH:MM',
    width: '200'
  }, {
    prop: 'operator',
    span: true,
    label: i18n.t('form.common.operator'),
    width: '160'
  }]
}
