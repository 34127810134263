import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/reward_product_categories/setup'
  })
}

export const getRewardProductCategories = (params) => {
  return request({
    method: 'GET',
    url: '/cms/reward_product_categories',
    params
  })
}

export const postRewardProductCategories = (data) => {
  return request({
    method: 'POST',
    url: '/cms/reward_product_categories',
    data
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/reward_product_categories/${id}/preview`
  })
}

export const putRewardProductCategories = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/reward_product_categories/${id}`,
    data
  })
}

export const getId = (id) => {
  return request({
    method: 'get',
    url: `/cms/reward_product_categories/${id}`
  })
}

export const deleteId = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/reward_product_categories/${id}`
  })
}

export const getRewardProductCategoryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/reward_product_categories/reward_product_category_list',
    params
  })
}
